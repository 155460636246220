
import {
  defineComponent,
  PropType,
  ref,
  computed,
  defineAsyncComponent,
} from 'vue';
import { InvestmentProp } from '@/types/Investment';

export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object as PropType<InvestmentProp>,
      default: () => ({} as InvestmentProp),
    },
  },
  components: {
    Create: defineAsyncComponent(() => import('./Create.vue')),
    Details: defineAsyncComponent(() => import('./Details.vue')),
  },
  setup: () => {
    const step = ref<number>(1);
    const modal = ref<any>(null);

    const determineStep = computed(() => {
      let route = '';
      switch (step.value) {
        case 1:
          route = 'create';
          break;
        case 2:
          route = 'details';
          break;
      }
      return route;
    });

    const nextStep = () => step.value++;
    const prevStep = () => step.value--;

    const open = () => modal.value.open();

    return { step, determineStep, prevStep, nextStep, open, modal };
  },
});
